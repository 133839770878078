Vue.component('the-header', {
    data: function () {
        return {
            scrolled: false,
            menuExpanded: false,
        }
    },
    methods: {
        toggleMenu: function () {
            this.menuExpanded = this.menuExpanded!== true;
        },
        watchScrollPosition: function () {
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
            this.scrolled = (scrollTop > 50);
        }
    },
    mounted: function() {
        let that = this;
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();
    }
});