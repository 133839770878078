Vue.component('painting-detail', {
    store,
    data: function() {
        return {
            showModal: false,
            showShopModal: false,
            lastPaintingIndex: null,
        }
    },
    mounted() {
        // retrieve history.length of latest index page
        this.lastPaintingIndex = sessionStorage.getItem("lastPaintingIndex");
    },
    methods: {
        goBackToIndex() {
            if(this.lastPaintingIndex = '') {
                Barba.Pjax.goTo(this.$refs.backlink.href)
            }
            else {
                Barba.Pjax.goTo(sessionStorage.getItem("lastPaintingIndex"));
            }
        },
        submit(e) {
            this.$root.submit(e);
        }
    }
});
