Vue.component('painting-box', {
    data: function() {
        return {
            hasFocus: false,
            thepreviousurl: "",
            thenexturl: "",
            showBackground: false,
        }
    },
    props: {
        previousurl: String,
        nexturl: String,
    },
    mounted() {
        let url = new URL(window.location.href);
        let focus = url.searchParams.get("focus");

        if (focus === "true") {
            this.hasFocus = true;
            this.thepreviousurl = this.previousurl + "?focus=true";
            this.thenexturl = this.nexturl + "?focus=true";
        }
        else {
            this.thepreviousurl = this.previousurl;
            this.thenexturl = this.nexturl;
        }

        this.arrowNavigation();

        // Not available for smaller touch devices
        if(window.outerWidth > 991) {
            this.zoomImage();
        }
    },
    methods: {
        arrowNavigation: function() {
            let that = this;
            document.onkeydown = checkKey;

            function checkKey(e) {
                if (typeof(e) === "undefined") { e = window.event; }

                else if(e.which == 27 && that.hasFocus === true) {
                    document.querySelector('.painting-detail__image-button--focus').click();
                }
                else if (e.keyCode == '37') {
                    document.querySelector('.painting-detail__image-button--previous').click();
                }
                else if (e.keyCode == '39') {
                    document.querySelector('.painting-detail__image-button--next').click();
                }
            }
        },
        makeFocus () {
            let url = new URL(window.location.href);
            let params = new URLSearchParams(url.search.slice(1));

            if(this.hasFocus === true) {
                this.hasFocus = false;
                this.showBackground = false;
                this.thepreviousurl = this.thepreviousurl.replace('?focus=true','');
                this.thenexturl = this.thenexturl.replace('?focus=true','');
                window.history.replaceState(null, null, this.removeParams("focus"));
            }
            else {
                this.hasFocus = true;
                this.thepreviousurl += "?focus=true";
                this.thenexturl += "?focus=true";
                window.history.replaceState(null, null, this.addParams("focus", "true"));
            }
        },
        removeParams(sParam) {
            let url = window.location.href.split('?')[0]+'?';
            let sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');
                if (sParameterName[0] != sParam) {
                    url = url + sParameterName[0] + '=' + sParameterName[1] + '&'
                }
            }
            return url.substring(0,url.length-1);
        },
        addParams(sParam, sValue) {
            let url = new URL(window.location.href);
            let query_string = url.search;
            let search_params = new URLSearchParams(query_string);

            search_params.append(sParam, sValue);

            url.search = search_params.toString();

            let new_url = url.toString();

            return new_url;
        },
        zoomImage() {
            const that = this

            this.$refs.image.addEventListener('mouseover', function (e) {
                that.showBackground = true;

                Vue.nextTick(() => {
                    if(typeof that.$refs.background !== 'undefined') {
                        that.$refs.background.addEventListener('mousemove', function (e) {
                            let zoomer = that.$refs.background;
                            let offsetX = e.offsetX ? e.offsetX : e.touches[0].pageX
                            let offsetY = e.offsetY ? e.offsetY : e.touches[0].pageY
                            let x = offsetX / zoomer.offsetWidth * 100
                            let y = offsetY / zoomer.offsetHeight * 100
                            zoomer.style.backgroundPosition = x + '% ' + y + '%';
                        })

                        that.$refs.background.addEventListener('mouseout', function (e) {
                            that.showBackground = false
                        })
                    }
                });
            })
        }
    }
});
