Vue.component('cookiescript-declaration', {
    name: 'CookiescriptDeclaration',
    props: {
        domainId: {
            type: String,
            required: true
        }
    },
    mounted() {
        const script = document.createElement('script');
        script.dataset.cookiescriptreport = 'report';
        script.onload = () => this.cleanup();
        script.src = '//report.cookie-script.com/r/' + this.domainId + '.js';
        this.$refs.target.appendChild(script);
    },
    methods: {
        cleanup() {
            this.$refs.target.querySelector('style').remove();

            Array.from(this.$refs.target.querySelectorAll('table:not(:has(tbody > tr))')).forEach(table => {
                table.previousElementSibling.remove();
                table.remove();
            });

            Array.from(this.$refs.target.querySelectorAll('button')).forEach(button => {
                button.classList.add('button', 'button--primary');
            });
        }
    }
});
