Vue.component('painting-index', {
    data() {
        return {
            goToPainting: false,
            sort: 'creation',
            direction: 'asc',
        }
    },
    methods: {
        watchScrollPosition() {
            if (!this.goToPainting) {
                // store new scrolling position on scroll
                let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
                sessionStorage.setItem("lastPaintingIndexScroll", scrollTop);
            }
        },
        changeSort() {
            let url = new URL(document.location);
            url.searchParams.set('sort', this.sort);
            window.location.href = url;
        },
        changeDirection() {
            let url = new URL(document.location);
            url.searchParams.set('dir', this.direction === 'asc' ? 'desc' : 'asc');
            window.location.href = url;
        }
    },
    mounted() {
        // store history.length in session
        sessionStorage.setItem("lastPaintingIndex", window.location.href);

        // retrieve last scrolling position
        let lastPaintingIndexScroll = sessionStorage.getItem("lastPaintingIndexScroll");

        // move to last scrolling position
        // console.log("lastPaintingIndexScroll: ", lastPaintingIndexScroll);
        window.scrollTo(0, lastPaintingIndexScroll);

        let that = this;

        // store new scrolling position on scroll
        window.addEventListener('scroll', function () {
            that.watchScrollPosition();
        });

        this.watchScrollPosition();

        // Populate sort vars from query string
        let url = new URL(document.location);
        if (['bible', 'thema', 'creation'].includes(url.searchParams.get('sort'))) {
            this.sort = url.searchParams.get('sort');
        }
        if (['asc', 'desc'].includes(url.searchParams.get('dir'))) {
            this.direction = url.searchParams.get('dir');
        }

    }
});
