Vue.component('painting-slider-component', {
    data: function () {
        return {
        }
    },
    mounted: function() {
        this.initSlider();
    },
    methods: {
        initSlider() {
            let slider = document.querySelector('.painting-slider__slides');

            if (slider) {
                this.paintingSlider = new Flickity(slider, {
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    cellAlign: 'left',
                    autoplay: true,

                });
            }
        },

        nextSlide: function() {
            this.paintingSlider.next(true, false);
        },
        prevSlide: function() {
            this.paintingSlider.previous(true, false);
        }
    }
});
