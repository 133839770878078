Mixins.queryString = {
    data() {
        return {
            cookieConsent: {
                necessary: false,
                preferences: false,
                statistics: false,
                targeting: false,
            }
        }
    },
    created() {
        let cookie =  document.cookie.match('(^|;)\\s*CookieScriptConsent\\s*=\\s*([^;]+)').pop() || false
        if (cookie) {
            let cookieObj = JSON.parse(cookie);
            this.cookieConsent.strict = cookieObj.categories.includes('strict');
            this.cookieConsent.performance = cookieObj.categories.includes('performance');
            this.cookieConsent.targeting = cookieObj.categories.includes('targeting');
            this.cookieConsent.unclassified = cookieObj.categories.includes('unclassified');
        }
    }
}
