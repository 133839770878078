Vue.component('form-component', {
    components: {
        'vue-recaptcha': VueRecaptcha
    },
    data: function () {
        return {
            hasCaptcha: false,
            captchaExecuted: false
        }
    },
    methods: {
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        this.$root.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });
        },
        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit();
        }
    },
});